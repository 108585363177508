<template>
  <div class="contents">
    <p class="key-txt">
      이모션글로벌과<br />
      아주 작은 아이디어까지도<br />
      완벽하게 이루어 보세요.
    </p>
    <div class="info-text">
      <address>
        <span class="add">서울 강남구 언주로 637 싸이칸홀딩스타워 5층</span>
        <span class="tel">T 02.542.1987</span>
        <span class="tel">F 02.542.1988</span>
        <a class="mail" href="mailto:inquiry@emotion.co.kr"> inquiry@emotion.co.kr </a>
        <a
          class="map"
          href="https://www.google.com/maps/place/%EC%9D%B4%EB%AA%A8%EC%85%98/@37.5134308,127.0269329,15z/data=!4m5!3m4!1s0x0:0xd5e7465ccc3f29d5!8m2!3d37.5137031!4d127.0350868"
          target="_blank"
          ><span>지도보기</span></a
        >
      </address>
    </div>
    <div class="request-wrap">
      <form class="request-form" @submit.prevent="uploadFile" ref="form">
        <h3 class="form-title">프로젝트의 내용을 알려주세요.</h3>

        <fieldset>
          <legend>사업분야 <span>다중 선택이 가능합니다.</span></legend>
          <ul class="form-list">
            <li v-for="item in projectBusinessType.list" :key="item.value">
              <form-checkbox
                :val="item.value"
                v-model="projectBusinessType.selected"
                :disabled="item.disabled"
              >
                {{ item.label }}
              </form-checkbox>
            </li>
          </ul>
        </fieldset>

        <fieldset>
          <legend>프로젝트 형태 <span>다중 선택이 가능합니다.</span></legend>
          <ul class="form-list">
            <li v-for="item in projectForm.list" :key="item.value">
              <form-checkbox
                :val="item.value"
                v-model="projectForm.selected"
                :disabled="item.disabled"
              >
                {{ item.label }}
              </form-checkbox>
            </li>
          </ul>
        </fieldset>

        <fieldset>
          <legend>프로젝트 성격 <span>다중 선택이 가능합니다.</span></legend>
          <ul class="form-list">
            <li v-for="item in projectCharacter.list" :key="item.value">
              <form-checkbox
                :val="item.value"
                v-model="projectCharacter.selected"
                :disabled="item.disabled"
              >
                {{ item.label }}
              </form-checkbox>
            </li>
          </ul>
        </fieldset>

        <fieldset>
          <legend>프로젝트 예산</legend>
          <form-select
            placeholder="예산을 선택해주세요."
            required
            v-model="projectBudget.value"
            :options="projectBudget.options"
          />
        </fieldset>
        <fieldset>
          <legend>프로젝트 기간</legend>
          <div class="project-period">
            <form-select
              placeholder="기간을 선택해주세요."
              required
              v-model="projectPeriod.value"
              :options="projectPeriod.options"
            />
            <div class="project-period-ipt" v-if="projectPeriod.value === 'PPT_DIRECT_INPUT'">
              <input
                ref="periodIpt"
                type="tel"
                v-model="formData.directPeriodInput"
                maxlength="2"
                pattern="[0-9]+"
              />
              <span>개월</span>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>선정 방식</legend>
          <ul class="form-list">
            <li v-for="item in selection.list" :key="item.value">
              <form-radio
                :disabled="item.disabled"
                required
                :val="item.value"
                name="selection"
                v-model="selection.value"
              >
                {{ item.label }}
              </form-radio>
            </li>
          </ul>
        </fieldset>

        <fieldset>
          <legend>프로젝트 주요 내용</legend>
          <div class="form-input-list ty2">
            <input type="text" v-model="formData.contactProjectName" placeholder="프로젝트 이름" />
          </div>
          <div class="memo-box">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="배경, 파견 인력 필요 유무 등 주요한 내용을 알려주세요."
              v-model="formData.contactContents"
            ></textarea>
            <ul class="file-list" v-if="uploadFiles">
              <li v-for="(item, index) in uploadFiles" :key="index">
                <span>{{ item.name }}({{ fileSize(item.size) }}MB)</span>
                <button type="button" class="file-del" @click="fileDelete(index)">삭제</button>
              </li>
            </ul>
            <div class="file-box">
              <input
                type="file"
                multiple
                @change="fileChange"
                accept=".pdf, .ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpg, .png, .zip"
              />
              <span class="find">파일첨부</span>
              <div class="desc">
                <p>
                  RFP, 참고문서 등 2개까지 업로드 가능<br />
                  (최대 50MB) pdf, ppt, word, excel, jpg, png
                </p>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>의뢰인 정보</legend>
          <ul class="form-input-list">
            <li>
              <input type="text" v-model="formData.contactClientName" placeholder="이름 / 직책" />
            </li>
            <li>
              <input type="email" v-model="formData.contactEmail" placeholder="이메일" />
            </li>
            <li>
              <input
                type="text"
                v-model="formData.contactClientCompanyName"
                placeholder="기관 혹은 기업명"
              />
            </li>
            <li>
              <input type="tel" v-model="formData.contactNumber" placeholder="전화번호" />
            </li>
            <li>
              <input
                type="url"
                v-model="formData.url"
                placeholder="(선택) 현재 운영중인 사이트 주소"
              />
            </li>
          </ul>

          <privacy :dialogVisible.sync="dialogVisible" />
          <div class="agree-box">
            <form-checkbox v-model="formData.privacy">
              <a href="#" @click.prevent="dialogVisible = true" class="under-red"
                >개인정보보호정책</a
              >에 동의합니다.
            </form-checkbox>
          </div>
        </fieldset>
        <div class="request-info">
          <div class="inner">
            <span :class="{active: projectBusinessType.selected.length}">
              {{ projectBusinessTypeList }}
            </span>
            <br />
            <span :class="{active: projectForm.selected.length}">
              {{ projectFormdList }}
            </span>
            의<br />
            <span :class="{active: projectCharacter.selected.length}">
              {{ projectCharacterdList }}
            </span>
            프로젝트를<br />
            의뢰하고 싶어요.<br /><br />
            <span :class="{active: projectBudget.value}">
              {{ projectBudgetValue }}
            </span>
            의 규모로<br />
            <span :class="{active: projectPeriod.value}">
              {{ projectPeriodValue }}
            </span>
            동안 진행할<br />
            예정입니다.<br /><br />
            선정 방식은
            <span :class="{active: selection.value}">
              {{ selectionValue }}
            </span>
            입니다.
          </div>
        </div>
        <div class="btn-area">
          <button type="submit" :disabled="validate">
            <span>문의하기</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  import {TweenMax, Power0} from "gsap";
  import {mapGetters} from "vuex";
  import {apiCode, apiFile, apiContact} from "@/api";
  import bus from "@/utils/bus";
  import privacy from "@/views/privacy/index";
  export default {
    name: "request",
    metaInfo() {
      return {
        title: "Request",
        titleTemplate: "%s | e · motion"
      };
    },
    data() {
      return {
        dialogVisible: false,
        formData: {
          contactProjectName: "",
          contactClientCompanyName: "",
          contactClientName: "",
          contactEmail: "",
          contactNumber: "",
          url: "",
          projectBudget: "",
          projectPeriod: "",
          directPeriodInput: "0",
          selection: "",
          contactContents: "",
          projectBusinessType: "",
          projectForm: "",
          projectCharacter: "",
          privacy: false,
          fileList: []
        },
        scene: null,
        projectBusinessType: {
          list: [],
          selected: []
        },
        projectForm: {
          list: [],
          selected: []
        },
        projectCharacter: {
          list: [],
          selected: []
        },
        projectBudget: {
          options: [],
          value: ""
        },
        projectPeriod: {
          options: [],
          value: ""
        },
        selection: {
          list: [],
          value: null
        },
        uploadFiles: []
      };
    },
    computed: {
      ...mapGetters(["GE_CONT_HEIGHT"]),
      projectBusinessTypeList() {
        return this.txt(
          this.projectBusinessType.selected,
          this.projectBusinessType.list,
          "사업분야"
        );
      },
      projectFormdList() {
        return this.txt(this.projectForm.selected, this.projectForm.list, "프로젝트 형태");
      },
      projectCharacterdList() {
        return this.txt(
          this.projectCharacter.selected,
          this.projectCharacter.list,
          "프로젝트 성격"
        );
      },
      projectBudgetValue() {
        return this.txt(
          this.projectBudget.value ? [this.projectBudget.value] : [],
          this.projectBudget.options,
          "프로젝트 예산"
        );
      },
      projectPeriodValue() {
        return this.txt(
          this.projectPeriod.value
            ? this.projectPeriod.value !== "PPT_DIRECT_INPUT"
              ? [this.projectPeriod.value]
              : `${this.formData.directPeriodInput} 개월`
            : [],
          this.projectPeriod.options,
          "프로젝트 기간"
        );
      },
      selectionValue() {
        return this.txt(
          this.selection.value ? [this.selection.value] : [],
          this.selection.list,
          "선정방식"
        );
      },
      validate() {
        return (
          !this.formData.projectBusinessType ||
          !this.formData.projectForm ||
          !this.formData.projectCharacter ||
          !this.formData.projectBudget ||
          !this.formData.projectPeriod ||
          (this.formData.projectPeriod === "PPT_DIRECT_INPUT" &&
            !parseInt(this.formData.directPeriodInput)) ||
          !this.formData.selection ||
          !this.formData.contactProjectName ||
          !this.formData.contactContents ||
          !this.formData.contactClientName ||
          !this.formData.contactEmail ||
          !this.formData.contactClientCompanyName ||
          !this.formData.contactNumber ||
          !this.formData.privacy
        );
      }
    },
    watch: {
      GE_CONT_HEIGHT() {
        this.init();
      },
      "projectBusinessType.selected"(val) {
        this.formData.projectBusinessType = val.join("/");
      },
      "projectForm.selected"(val) {
        this.formData.projectForm = val.join("/");
      },
      "projectCharacter.selected"(val) {
        this.formData.projectCharacter = val.join("/");
      },
      "projectBudget.value"(val) {
        this.formData.projectBudget = val;
      },
      "projectPeriod.value"(val) {
        this.formData.projectPeriod = val;
        if (val === "PPT_DIRECT_INPUT") {
          setTimeout(() => {
            this.$refs.periodIpt.focus();
          }, 1);
        }
      },
      "selection.value"(val) {
        this.formData.selection = val;
      }
    },
    components: {
      privacy
    },
    mounted() {
      this.init();
      this.code("BUSINESS_TYPE", this.projectBusinessType.list);
      this.code("PROJECT_FORM_TYPE", this.projectForm.list);
      this.code("PROJECT_CHARACTER_TYPE", this.projectCharacter.list);
      this.code("PROJECT_BUDGET_TYPE", this.projectBudget.options);
      this.code("PROJECT_PERIOD_TYPE", this.projectPeriod.options);
      this.code("SELECTION_TYPE", this.selection.list);
    },
    methods: {
      fileSize(size) {
        let value = size / 1024 / 1024;
        let exp = -1;
        if (typeof exp === "undefined" || +exp === 0) {
          return Math.ceil(value);
        }
        value = +value;
        exp = +exp;
        if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
          return NaN;
        }
        value = value.toString().split("e");
        value = Math.ceil(+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
        value = value.toString().split("e");
        return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
      },
      fileDelete(index) {
        this.uploadFiles.splice(index, 1);
      },
      fileChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (this.uploadFiles.length + files.length > 2) {
          alert("RFP, 참고문서 등 2개까지 업로드 가능 합니다.");
          return;
        }
        files.forEach(el => {
          if (el.size <= 1024 * 1024 * 50) {
            this.uploadFiles.push(el);
          } else {
            alert("최대 50MB까지 업로드 가능 합니다.");
          }
        });
      },
      async uploadFile() {
        bus.$emit("loading", true);
        //await this.submit();
        this.formData.fileList = [];
        Promise.all(
          this.uploadFiles.map(async el => {
            try {
              const formData = new FormData();
              formData.append("files", el);
              const {
                data: {data: response}
              } = await apiFile("contact", formData);
              this.formData.fileList.push({
                contactFileName: response[0].originName,
                contactPhysicalFileName: response[0].physicalName
              });
            } catch (e) {
              console.log(e);
            }
          })
        )
          .then(() => {
            this.submit();
          })
          .catch(e => {
            bus.$emit("loading", false);
            console.log(e);
          });
      },
      async submit() {
        try {
          const {data: response} = await apiContact(this.formData);
          if (response.code === "SUCCESS") {
            await this.$refs.form.reset();
            alert("문의가 완료 되었습니다.");
            this.formData = {
              contactProjectName: "",
              contactClientCompanyName: "",
              contactClientName: "",
              contactEmail: "",
              contactNumber: "",
              url: "",
              projectBudget: "",
              projectPeriod: "",
              directPeriodInput: "0",
              selection: "",
              contactContents: "",
              projectBusinessType: "",
              projectForm: "",
              projectCharacter: "",
              privacy: false,
              fileList: []
            };
            this.projectBusinessType.selected = [];
            this.projectForm.selected = [];
            this.projectCharacter.selected = [];
            this.projectBudget.value = null;
            this.projectPeriod.value = null;
            this.selection.value = null;
            this.uploadFiles = [];
          } else {
            alert(response.message);
          }

          await bus.$emit("loading", false);
        } catch (e) {
          bus.$emit("loading", false);
          alert(e.response.data.message);
        }
      },
      async code(code, targetData) {
        try {
          const {
            data: {data: response}
          } = await apiCode(code);
          response.forEach(el => {
            targetData.push({
              value: el.commonCode,
              label: el.commonCodeDescription
            });
          });
        } catch (e) {
          console.log(e);
          return [];
        }
      },
      txt(a, b, c) {
        if (typeof a === "string") {
          return a;
        } else {
          const val = a
            .map(el => {
              return b.filter(t => el === t.value)[0].label;
            })
            .join(", ");
          return a.length ? val : c;
        }
      },
      init() {
        this.scene?.destroy(true);
        const item = document.querySelector(".request-info");
        const inner = item.querySelector(".inner");
        const tween = TweenMax.to(inner, 1, {
          ease: Power0.easeInOut,
          y: item.clientHeight - inner.clientHeight
        });
        this.scene = this.$scrollmagic
          .scene({
            triggerElement: item,
            triggerHook: 0,
            duration: item.clientHeight - inner.clientHeight
          })
          .setTween(tween);
        this.$scrollmagic.addScene(this.scene);
      }
    },
    destroyed() {
      this.scene?.destroy(true);
    }
  };
</script>
<style lang="scss" scoped>
  .form-select {
    margin-top: 28px;
  }
  .project-period {
    position: relative;
    &-ipt {
      position: relative;
      margin-top: 19px;
      input {
        width: 100%;
        height: 37px;
        box-sizing: border-box;
        line-height: 36px;
        outline: none;
        border: solid #ddd;
        border-width: 0 0 1px;
        font-size: 14px;
      }
      span {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        line-height: 37px;
      }
    }
  }
  .contents {
    padding-bottom: 80px;
  }
  .key-txt {
    margin-top: 121px;
    font-size: 30px;
    line-height: 43px;
    font-weight: bold;
    letter-spacing: -0.03em;
  }
  .info-text {
    position: relative;
    margin-top: 23px;
    color: #333;
    &:before {
      content: "";
      display: block;
      width: 22px;
      height: 28px;
      background: url(../../assets/images/icon_address.png) no-repeat 0 / cover;
    }
    .add {
      display: block;
      margin: 15px 0;
      font-size: 13px;
      line-height: 13px;
    }
    .tel {
      display: block;
      @include montserrat;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0;
    }
    .mail {
      display: block;
      @include montserrat;
      font-size: 11px;
      line-height: 19px;
      letter-spacing: 0;
    }
    .map {
      display: inline-block;
      margin: 12px 0 0 -10px;
      padding: 10px;
      font-size: 13px;
      color: #000;
      font-weight: bold;
      span {
        line-height: 19px;
        border-bottom: 1px solid #000;
      }
    }
  }
  .form-title {
    display: block;
    margin-top: 60px;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
  }
  fieldset {
    margin-top: 32px;
    legend {
      display: flex;
      font-size: 13px;
      line-height: 15px;
      font-weight: bold;
      align-items: center;
      span {
        margin-left: 15px;
        font-size: 11px;
        font-weight: normal;
        color: #b4b4b4;
      }
    }
    .agree-box {
      margin-top: 20px;
      label {
        width: 100%;
        padding: 10px 0;
      }
    }
    .under-red {
      color: $red;
      text-decoration: underline;
    }
    .form-list {
      margin-top: 28px;
      @include length(3);
      label::v-deep {
        height: 36px;
        line-height: 36px;
        box-sizing: border-box;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 26px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 13px;
        letter-spacing: -0.05em;
        transition: all 200ms ease-in-out 0s;
        i {
          display: none;
        }
        input {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
        }
        &.disabled {
          cursor: default;
          color: #ddd;
        }
        &.checked {
          background: $red;
          color: #fff;
          &.disabled {
            opacity: 0.5;
          }
        }
      }
    }
    .form-input-list {
      margin-top: 39px;
      &.ty2 {
        margin-top: 20px;
      }
      & ~ .memo-box {
        margin-top: 20px;
      }
      li {
        margin-top: 19px;
        &:first-child {
          margin-top: 0;
        }
      }
      input {
        width: 100%;
        height: 37px;
        line-height: 36px;
        outline: none;
        border: solid #ddd;
        border-width: 0 0 1px;
        font-size: 13px;
        &::placeholder {
          color: #b4b4b4;
        }
        &:focus {
          border-color: $red;
        }
      }
    }
  }
  /*  .memo-box { //todo 공통 처리
    margin-top: 30px;
    padding: 18px 20px 26px;
    border: 1px solid #ddd;
    font-size: 11px;
    textarea {
      width: 100%;
      height: 157px;
      box-sizing: border-box;
      resize: none;
      outline: none;
      border: none;
      line-height: 15px;
      &::placeholder {
        color: #b4b4b4;
      }
    }
    .file-box {
      position: relative;
      margin-top: 18px;
      input[type="file"] {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
      .find {
        position: absolute;
        bottom: 10px;
        left: 0;
        display: inline-flex;
        width: 65px;
        height: 28px;
        justify-content: center;
        border-radius: 13px;
        font-weight: bold;
        color: #fff;
        line-height: 28px;
        background: #333;
      }
      .txt {
        position: relative;
        padding: 0 29px 0 15px;
        box-sizing: border-box;
        background: #f6f6f6;
        p {
          height: 36px;
          line-height: 36px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      ul li {
        position: relative;
        padding: 0 29px 0 15px;
        box-sizing: border-box;
        background: #f6f6f6;
        height: 36px;
        line-height: 36px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        & + li {
          margin-top: 10px;
        }
      }
      .file-del {
        z-index: 3;
        position: absolute;
        top: 0;
        right: 0;
        width: 29px;
        height: 100%;
        background: url(../../assets/images/file-del.png) no-repeat center / 8px 8px;
        text-indent: -999px;
        overflow: hidden;
        outline: none;
        border: none;
      }
      .desc {
        overflow: hidden;
        margin-top: 21px;
        padding-left: 75px;
        font-size: 10px;
        line-height: 15px;
        color: #888;
        p {
          position: relative;
          padding-left: 24px;
          letter-spacing: -0.05e;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 15px;
            height: 15px;
            background: url(../../assets/images/file-desc.png) no-repeat 0 / cover;
          }
        }
      }
    }
  }*/
  .request-info {
    margin-top: 70px;
    .inner {
      position: relative;
      padding: 16px 0;
      will-change: transform;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #333;
      letter-spacing: -0.05em;
      word-break: keep-all;
      span {
        color: #b4b4b4;
        &.active {
          color: $red;
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: $red;
      }
    }
  }
  .btn-area {
    display: flex;
    margin-top: 37px;
    justify-content: center;
    align-items: center;
    button {
      width: 120px;
      height: 40px;
      border-radius: 20px;
      background: #ee2c3c;
      border: 0;
      span {
        color: #fff;
        font-family: "Noto Sans KR", sans-serif;
        font-size: 15px;
        font-weight: bold;
      }
      &:disabled {
        background: #ddd;
      }
    }
  }
</style>
